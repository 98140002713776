import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AciuPage = () => (
  <Layout>
    <SEO title="Užklausa pateikta" />
    <section>
      <div className="container">
        <div className="row">
          <div className='col-md-6 m-auto text-center'>
            <div className="thanks-check">
            <svg id="checkmark-svg" className="run-animation" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 193.3 193.3"><circle class="cls-1 circle" cx="96.65" cy="96.65" r="94.15"/><polyline class="cls-1 checkmark" points="46.9 101.4 76.9 131.4 146.4 61.9"/></svg>
            </div>
        
            <h1>Ačiū! Jūsų užklausą gavau.</h1>
            <p>Artimiausiu metu su Jumis susisieksiu telefonu arba el. paštu.</p>
          </div>
        </div>
      </div>
    </section>

  </Layout>
)

export default AciuPage